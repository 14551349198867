<template>
  <b-row class="row-no-margin message-area">
    <b-col v-if="theOnlyOneInThread" cols="12" md="10" class="text-center pt-4">
      <b>{{ 'MESSAGE_RESPOND_ONLY_ONE' | fromTextKey }}</b>
    </b-col>
    <b-col v-if="disabled" cols="12" md="10" class="text-center pt-4">
      <b>{{ 'MESSAGE_RESPOND_DISABLED' | fromTextKey }}</b>
      <span class="leave-timestamp"
        >{{ 'MESSAGE_RESPOND_DISABLED_TIMESTAMP_PREFIX' | fromTextKey }}
        <post-time :time="shownSubscription.leaveTime" variation="long" />
      </span>
    </b-col>
    <b-col v-else cols="12" class="message-textarea" :class="{ active: showTextarea }">
      <div v-if="!showTextarea" class="prefixIcon">
        <span class="sr-only">{{ placeHolderText }}</span>
        <div v-button class="preIcon truncate" @click="handleTextareaClicked">
          <Icon :name="iconClassEnum.CONVERSATION" />
          <span class="truncate">{{ placeHolderText }}</span>
        </div>
        <b-dropdown
          v-if="canSelectSendingOptions"
          ref="sendOptionDropdown"
          class="send-option-dropdown"
          variant="primary"
          boundary="viewport"
          no-caret
          :right="!isMobile"
          menu-class="send-options"
        >
          <template #button-content>
            <Icon class="send-button-icon" :name="iconClassEnum.DOWN_ARROW" />
            <span class="sr-only">{{ $t('messages.response_option') }}</span>
          </template>
          <b-dropdown-item-btn v-if="canReplyToCreator" @click="handleReplyToCreatorClicked">
            <div class="send-option">
              <Icon class="answer-icon" :name="iconClassEnum.REDIRECT" />
              <div>
                <div class="answer-option-label">{{ 'MESSAGE_START_NEW_THREAD' | fromTextKey }}</div>
                <div class="answer-option-receiver">{{ creator.fullName }}</div>
              </div>
            </div>
          </b-dropdown-item-btn>
          <b-dropdown-item-btn @click="enableTextarea">
            <div class="send-option">
              <Icon class="answer-all-icon" :name="iconClassEnum.REPLY_ALL" />
              <div>
                <div class="answer-option-label">{{ 'MESSAGE_START_NEW_THREAD_TO_ALL' | fromTextKey }}</div>
                <div class="answer-option-receiver">{{ recipientShortList }}</div>
              </div>
            </div>
          </b-dropdown-item-btn>
          <b-dropdown-text class="py-1">
            <IconContainer v-button class="ml-auto" @click.stop="$refs.sendOptionDropdown.hide(true)">
              <Icon
                class="close-icon"
                :name="iconClassEnum.CLOSE"
                :aria-label="'ARIA_LABEL_MESSAGE_OPTION_CLOSE' | fromTextKey"
              />
            </IconContainer>
          </b-dropdown-text>
        </b-dropdown>
        <b-button v-else variant="primary" class="button-send" @click="handleTextareaClicked">
          {{ isMultipleRecipients ? 'MESSAGE_RESPOND_TO_ALL' : 'MESSAGE_RESPOND' | fromTextKey }}
        </b-button>
      </div>
      <transition name="toggle">
        <div v-if="showTextarea">
          <h5 class="placeholder-text" :class="{ truncate: !isMobile }">
            {{ placeHolderText }}
          </h5>
          <PluginTextEditor
            v-if="!isLoading"
            id="replyMessageBox"
            ref="messageTextareaEditor"
            toolbar-type="basic"
            :institution-code="shownSubscription.institutionCode"
            :fixed-size="fixedSize"
            :auto-focus="true"
            :rows="1"
            :no-resize="true"
            :max-length="maxLength"
            :include-secure="profile.role == portalRoles.EMPLOYEE"
            :class="{
              disabled: disabled,
              'red-border': !validMessageText || !validMessageTextLength,
            }"
            class="scrollbar message-textarea message-textarea-editor"
            :disabled="disabled"
            :value="disabled ? '' : message"
            :max-resize-height="isMobile ? 125 : null"
            :clean="cleanEditor"
            :aria-label="'ARIA_LABEL_WRITE_MESASGE_CONTENT'"
            :min-height="40"
            :min-content-height="16"
            @textTooLong="disableButton"
            @documentAddedToEditor="markAsSensitive()"
            @input="setCurrentMessage"
            @initialize="onEditorInitialized"
          />
          <b-alert variant="danger" :show="!validMessageText">
            {{ 'MESSAGE_VALIDATE_TEXT' | fromTextKey }}
          </b-alert>
          <b-alert variant="danger" :show="!validMessageTextLength">
            {{ 'POST_CONTENT_TOO_LONG' | fromTextKey }}
          </b-alert>
          <div class="message-utility-container">
            <upload
              ref="uploaderFiles"
              :selected-institution-code-for-filter="
                profile.role !== portalRoles.OTP ? shownSubscription.institutionCode : ''
              "
              :allow-media="hasPermissionToAttachMedia"
              :upload-from-filesharing="canAttachFromSecureFiles"
              :upload-from-gallery="profile.role !== portalRoles.OTP"
              :existing-media="existingMedia"
              :institution-code-for-tagging="albumInstitutionCode"
              @attachSecureDocuments="attachSecureDocuments"
              @mediaUpdated="filesChanged"
            />
            <div class="d-flex align-items-center">
              <div v-show="isVisibleSensitiveCheckBox" class="sensitive-msg-container">
                <b-form-checkbox
                  v-model="markedSensitive"
                  class="d-flex align-items-center"
                  :value="true"
                  :unchecked-value="false"
                  :disabled="hasSecureDocuments || canChangeSensitivityLevel"
                  @change="confirmMarkSensitive"
                >
                  {{ createSensitiveMessageText }}
                  <Icon :name="iconClassEnum.LOCK" class="ml-1" />
                </b-form-checkbox>
                <help-box
                  id="dropdown-sensitive-tip"
                  title=""
                  class="ml-2"
                  :align-right="true"
                  :body="$options.filters.fromTextKey('MESSAGE_DROPDOWN_SENSITIVE_HELP')"
                />
              </div>
              <div v-if="isVisibleSensitiveIndicator" class="sensitive-msg-container">
                <strong>{{ markedAsSensitiveText }}</strong>
                <Icon :name="iconClassEnum.LOCK" class="ml-1" />
              </div>
            </div>
          </div>
          <aula-form-footer
            :is-loading="isLoading"
            class="footer"
            :show-line="false"
            :show-cancel-mobile="true"
            button-shape-mobile="square"
            :submit-disabled="disabled || submitDisabled"
            @cancelClicked="onCancelClicked"
            @submitClicked="checkForAutoreply"
          >
            <template #submit>
              <div class="d-flex align-items-center">
                <Icon v-if="isMultipleRecipients" class="btn-icon-submit" :name="iconClassEnum.REPLY_ALL" />
                {{ submitTextKey | fromTextKey }}
              </div>
            </template>
          </aula-form-footer>
        </div>
      </transition>
      <div class="clearfix" />
      <aula-modal
        ref="autoReplyModal"
        header-text="MESSAGE_WARNING_HEADER"
        @cancelClicked="$refs.autoReplyModal.hide()"
        @okClicked="acceptWarning"
      >
        {{ 'MESSAGE_WARNING_BODY' | fromTextKey }}
      </aula-modal>
      <aula-modal
        ref="communicationBlockedWarning"
        header-text="MESSAGE_WARNING_HEADER"
        :show-cancel="false"
        @okClicked="$refs.communicationBlockedWarning.hide()"
      >
        {{ 'WARNING_THE_COMMUNICATION_CHANNEL_IS_BLOCKED' | fromTextKey }}
      </aula-modal>
      <aula-modal
        ref="markAsSensitiveModal"
        ok-text="YES"
        header-text="MESSAGE_WARNING_HEADER"
        @closeClicked="cancelUnmarkSensitive"
        @cancelClicked="cancelUnmarkSensitive"
        @okClicked="markAsSensitive()"
      >
        {{ 'WARNING_STEPUP_MARK_MESSAGE_AS_SENSITIVE' | fromTextKey }}<br />
        {{ 'WARNING_STEPUP_MARK_MESSAGE_AS_SENSITIVE_2' | fromTextKey }}
      </aula-modal>
      <aula-modal
        ref="unmarkAsSensitiveModal"
        ok-text="YES"
        header-text="MESSAGE_WARNING_HEADER"
        @closeClicked="cancelMarkSensitive"
        @cancelClicked="cancelMarkSensitive"
        @okClicked="unmarkAsSensitive()"
      >
        {{ 'UNMARK_AS_SENSITIVE_MESSAGE' | fromTextKey }}<br />
        {{ 'UNMARK_AS_SENSITIVE_MESSAGE_2' | fromTextKey }}
      </aula-modal>
      <aula-modal
        ref="draftDiscardWarningModal"
        ok-text="YES_DELETE"
        header-text="MESSAGE_WARNING_HEADER"
        @cancelClicked="$refs.draftDiscardWarningModal.hide()"
        @okClicked="confirmCancelDraft"
        role="alertdialog"
        aria-labelledby="modal-header"
        aria-describedby="modal-content"
      >
        <div id="modal-content" aria-live="assertive">
          {{ draftDiscardWarningMessage }}<br />
          {{ 'MESSAGE_DISCARD_DRAFT_2' | fromTextKey }}
        </div>
      </aula-modal>
    </b-col>
  </b-row>
</template>

<script>
import upload from '../../../shared/components/Upload.vue';
import { types } from '../../store/types/types';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { portalRoles } from '../../../shared/enums/portalRoles';
import Vue from 'vue';
import { messageOwnerTypes } from '../../../shared/enums/messageOwnerTypes';
import { sensitivityLevel } from '../../../shared/enums/sensitivityLevel';
import $ from 'jquery';
import { messageProviderKeyEnum } from '../../../shared/enums/messageProviderKeyEnum';
import isEqual from 'lodash/isEqual';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import PluginTextEditor from '../text_editor/PluginTextEditor.vue';
import IconContainer from '../../../shared/components/IconContainer.vue';
import Button from '../../../shared/directives/button';
import { MessageFormActionEnum } from './enums/messageFormActionEnum';
import { hasBlockedCommunicationChannelForMessageSubscription } from '../../../shared/utils/blockedCommunicationChannelUtil';

export default {
  components: {
    IconContainer,
    PluginTextEditor,
    Icon,
    upload,
  },
  directives: {
    Button,
  },
  inject: {
    restartPollingService: messageProviderKeyEnum.RESTART_POLLING_SERVICE,
    setCurrentMessage: messageProviderKeyEnum.SET_CURRENT_MESSAGE,
    onCancelEditingMessage: messageProviderKeyEnum.ON_CANCEL_EDITING_MESSAGE,
    resetMessageTextarea: messageProviderKeyEnum.RESET_MESSAGE_TEXTAREA,
    setCleanEditor: messageProviderKeyEnum.SET_CLEAN_EDITOR,
    setShowTextarea: messageProviderKeyEnum.SET_SHOW_TEXTAREA,
    setActiveEditor: messageProviderKeyEnum.SET_ACTIVE_EDITOR,
    updateHasMessageChanged: messageProviderKeyEnum.UPDATE_HAS_MESSAGE_CHANGED,
    getIsReminderThread: messageProviderKeyEnum.GET_IS_REMINDER_THREAD,
    setMessageFormData: messageProviderKeyEnum.SET_MESSAGE_FORM_DATA,
  },
  props: {
    shownSubscription: Object,
    message: { type: String, default: '' },
    isEditingMessage: { type: Boolean, default: false },
    editedMessageDto: { type: Object, default: null },
    cleanEditor: { type: Boolean, default: false },
    showTextarea: { type: Boolean, default: false },
    existingMedia: { type: Array, default: () => [] },
  },
  data: function () {
    return {
      sensitivityLevel: sensitivityLevel,
      markedSensitive: false,
      isLoading: false,
      submitDisabled: false,
      fixedSize: true,
      inSave: null,
      validMessageText: true,
      validMessageTextLength: true,
      maxLength: 10000,
      maxLengthWithoutHtml: 6600,
      attachments: [],
      portalRoles: portalRoles,
      recipientShortList: '',
      hasSecureDocuments: false,
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    ...mapGetters({
      subscriptions: types.MESSAGES_GET_SUBSCRIPTIONS,
      autoReply: types.MESSAGES_GET_AUTOREPLY,
      chosenFolderAndMailOwner: types.MESSAGES_GET_CHOSEN_FOLDER_AND_MAIL_OWNER,
      profile: types.GET_CURRENT_PROFILE,
      hasPermission: types.HAS_PERMISSION,
      messages: types.MESSAGES_GET,
      institutions: types.GET_INSTITUTIONS,
      isMobile: types.GET_IS_MOBILE,
      isSteppedUp: types.GET_GLOBAL_STEPPED_UP,
      activeAlbum: types.GET_ACTIVE_ALBUM,
      otpInboxes: types.MESSAGES_GET_OTP_INBOXES,
    }),
    markedAsSensitiveText() {
      if (this.isMobile) {
        return this.$options.filters.fromTextKey('MESSAGE_CREATE_SENSITIVE_MOBILE');
      }
      return this.$options.filters.fromTextKey('MESSAGE_MARKED_AS_SENSITIVE');
    },
    commonInboxId() {
      return this.shownSubscription.mailBoxOwner.mailBoxOwnerType === messageOwnerTypes.COMMON_INBOX
        ? this.shownSubscription.mailBoxOwner.id
        : null;
    },
    otpInboxId() {
      return this.shownSubscription.mailBoxOwner.mailBoxOwnerType === messageOwnerTypes.OTP_INBOX
        ? this.shownSubscription.mailBoxOwner.id
        : null;
    },
    draftDiscardWarningMessage() {
      if (this.canSelectSendingOptions) {
        return this.$options.filters.fromTextKey('MESSAGE_DISCARD_DRAFT_ALL_USERS');
      }
      return this.$options.filters.fromTextKey('MESSAGE_DISCARD_DRAFT_1');
    },
    creator() {
      return this.shownSubscription.threadCreator;
    },
    canReplyToCreator() {
      let isDeactivated = false;
      switch (this.shownSubscription.threadCreator.mailBoxOwner.mailBoxOwnerType) {
        case messageOwnerTypes.INSTITUTION_PROFILE:
          isDeactivated = this.shownSubscription.threadCreator.mailBoxOwner.isDeactivated;
          break;
        case messageOwnerTypes.OTP_INBOX:
          isDeactivated = this.shownSubscription.threadCreator.mailBoxOwner.isDeactivated;
          break;
        case messageOwnerTypes.COMMON_INBOX:
          isDeactivated = this.shownSubscription.threadCreator.mailBoxOwner.isDeleted;
          break;
      }
      return !this.isCreator && !isDeactivated;
    },
    canSelectSendingOptions() {
      return this.isMultipleRecipients;
    },
    isCreator() {
      if (
        this.chosenFolderAndMailOwner.mailOwnerId === this.shownSubscription.threadCreator.mailBoxOwner.id &&
        this.chosenFolderAndMailOwner.mailOwnerType ===
          this.shownSubscription.threadCreator.mailBoxOwner.mailBoxOwnerType
      ) {
        return true;
      }
      return this.shownSubscription.threadCreator.mailBoxOwner.profileId === this.profile.profileId;
    },
    placeHolderText() {
      let placeHolderText = '';
      if (this.recipientShortList) {
        placeHolderText = `${this.$options.filters.fromTextKey('MESSAGE_RESPOND_PLACEHOLDER')} ${
          this.recipientShortList
        }`;
      }
      if (this.isEditingMessage) {
        placeHolderText = Vue.filter('fromTextKey')('MESSAGE_EDITING_MESSAGE');
      }
      if (this.canSelectSendingOptions && !this.showTextarea) {
        placeHolderText = this.$options.filters.fromTextKey('MESSAGE_TEXTAREA_PLACEHOLDER');
      }
      return placeHolderText;
    },
    albumInstitutionCode() {
      if (this.activeAlbum?.creator) {
        return this.activeAlbum.creator.institutionCode;
      }
      return null;
    },
    submitTextKey() {
      if (this.isEditingMessage) {
        return 'BUTTON_SAVE';
      }
      if (this.isMultipleRecipients) {
        return 'MESSAGE_ANSWER_TO_ALL';
      }
      return 'MESSAGE_RESPOND';
    },
    isReminderThread() {
      return this.getIsReminderThread();
    },
    isVisibleSensitiveCheckBox() {
      if (this.isReminderThread) {
        return false;
      }
      if (!this.markedSensitive) {
        return true;
      }
      return this.profile.role === portalRoles.EMPLOYEE;
    },
    isVisibleSensitiveIndicator() {
      return !this.isVisibleSensitiveCheckBox && this.shownSubscription.sensitive;
    },
    canChangeSensitivityLevel() {
      if (this.markedSensitive === false) {
        return false;
      }

      if (this.isSteppedUp === true) {
        return false;
      }

      return true;
    },
    createSensitiveMessageText() {
      if (this.isMobile) {
        return Vue.filter('fromTextKey')('MESSAGE_CREATE_SENSITIVE_MOBILE');
      }

      return Vue.filter('fromTextKey')('MESSAGE_CREATE_SENSITIVE');
    },
    isMultipleRecipients() {
      const remainRecipientNumber = this.shownSubscription.recipients.filter(
        recipient => recipient.leaveTime === null
      ).length;
      return remainRecipientNumber > 2;
    },
    canAttachFromSecureFiles() {
      return (
        this.profile.role == portalRoles.EMPLOYEE &&
        (this.hasPermission(permissionEnum.HANDLE_SECURE_FILES) ||
          this.hasPermission(permissionEnum.HANDLE_SECURE_FILES_LIMITED))
      );
    },
    disabled() {
      return this.shownSubscription.leaveTime != null;
    },
    theOnlyOneInThread() {
      const recipientsInThread = this.shownSubscription.recipients.filter(rec => rec.leaveTime == null);
      if (recipientsInThread.length !== 1) {
        return false;
      }
      if (this.chosenFolderAndMailOwner.mailOwnerId === recipientsInThread[0].mailBoxOwner.id) {
        return true;
      }
      return recipientsInThread[0].mailBoxOwner.profileId === this.profile.profileId;
    },
    hasPermissionToAttachMedia() {
      return this.hasPermission(permissionEnum.HANDLE_MEDIA);
    },
  },
  watch: {
    message(message) {
      if (message && message.length > 0) {
        this.setCleanEditor(false);
        if (
          !this.markedSensitive &&
          (message.includes('/dokumenter/sikre/external/') || message.includes('/dokumenter/sikre/internal/'))
        ) {
          this.markAsSensitive();
        }
      }
      this.validateMessageText();
    },
    'shownSubscription.sensitive'() {
      this.markedSensitive = this.shownSubscription.sensitive;
    },
    'shownSubscription.recipients'() {
      this.calculatePlaceHolder();
    },
    showTextarea() {
      if (!this.showTextarea) {
        this.collapseReplyBox();
      }
    },
    attachments() {
      const hasAttachmentsChanged = isEqual(this.existingMedia, this.attachments) === false;
      if (hasAttachmentsChanged) {
        this.updateHasMessageChanged(true);
      }
    },
  },
  mounted() {
    this.markedSensitive = this.shownSubscription.sensitive;
    this.calculatePlaceHolder();
    if (this.$store.state.messages.currentDraftMessage.length > 0) {
      this.enableTextarea();
    }
  },
  updated() {
    if (!this.shownSubscription.leaveTime && !this.isEditingMessage) {
      this.saveDraftMessage({
        id: this.shownSubscription.id,
        navLocal: this.$route.name === 'uniqueThread',
      });
    }
  },
  beforeDestroy() {
    this.resetMessageTextarea();
  },
  methods: {
    ...mapMutations({
      setStepUpNotification: types.MUTATE_SET_NOTIFICATION_STEP_UP,
    }),
    ...mapActions({
      replyMessage: types.REPLY_MESSAGE,
      editMessage: types.EDIT_MESSAGE,
      adjustSensitivity: types.ACTIONS_ADJUST_SENSITIVITY,
      createAttachments: types.CREATE_ATTACHMENTS,
      updateAttachments: types.UPDATE_ATTACHMENTS,
      saveDraftMessage: types.MESSAGES_SAVE_DRAFT_MESSAGE,
      createDocumentLinks: types.CREATE_DOCUMENT_LINKS,
    }),
    confirmCancelDraft() {
      this.$refs.draftDiscardWarningModal.hide();
      this.resetMessageTextarea();
      this.setCurrentMessage('');
    },
    async hasBlockedCommunicationChannel() {
      return hasBlockedCommunicationChannelForMessageSubscription(
        this.shownSubscription,
        this.shownSubscription.threadCreator
      );
    },
    async handleReplyToCreatorClicked() {
      if (await this.hasBlockedCommunicationChannel()) {
        this.$refs.communicationBlockedWarning.show();
        return;
      }

      this.setMessageFormData({
        threadId: this.shownSubscription.id,
        commonInboxId: this.commonInboxId,
        otpInboxId: this.otpInboxId,
        action: MessageFormActionEnum.ANSWER_THREAD_CREATOR,
      });
      await this.$router.push({
        name: 'newMessage',
      });
    },
    handleTextareaClicked() {
      if (this.canSelectSendingOptions) {
        this.$refs.sendOptionDropdown.show();
      } else {
        this.enableTextarea();
      }
    },
    onEditorInitialized(editor) {
      this.setActiveEditor(editor);
    },
    attachSecureDocuments(documents) {
      this.markAsSensitive();
      this.$refs.uploaderFiles.addNewMedia(documents);
    },
    confirmMarkSensitive() {
      if (this.markedSensitive) {
        this.$refs.markAsSensitiveModal.show();
      } else {
        this.$refs.unmarkAsSensitiveModal.show();
      }
    },
    disableButton(state) {
      this.submitDisabled = state;
    },
    markAsSensitive(showWToaster) {
      this.adjustSensitivity({
        subscriptionId: this.shownSubscription.id,
        sensitivityLevel: sensitivityLevel.HIGH,
        showWToaster: showWToaster,
      });
      this.markedSensitive = true;
      this.$refs.markAsSensitiveModal.hide();

      if (!this.isSteppedUp) {
        this.setStepUpNotification({
          showStepUpNotification: true,
          redirectedUrl: window.location.href,
        });
      }
    },
    unmarkAsSensitive() {
      this.adjustSensitivity({
        subscriptionId: this.shownSubscription.id,
        sensitivityLevel: sensitivityLevel.MEDIUM,
      });
      this.markedSensitive = false;
      this.$refs.unmarkAsSensitiveModal.hide();
    },
    cancelUnmarkSensitive() {
      this.markedSensitive = false;
      this.$refs.markAsSensitiveModal.hide();
    },
    cancelMarkSensitive() {
      this.markedSensitive = true;
      this.$refs.unmarkAsSensitiveModal.hide();
    },
    filesChanged(attachments) {
      this.attachments = attachments;
      this.hasSecureDocuments = attachments.some(att => att.file && att.file.attachedSecureDocumentId);
    },
    validateMessageText() {
      if (this.cleanEditor) {
        return;
      }
      const textWithoutHtml = Vue.filter('textWithoutHtml')(this.message);
      this.validMessageTextLength = textWithoutHtml.length <= this.maxLengthWithoutHtml;
      this.validMessageText = this.message && this.message.length > 0 && textWithoutHtml.length > 0;
    },
    async prepareMessage() {
      this.isLoading = true;
      this.attachments = Vue.filter('prepareAttachments')(this.attachments);
      if (this.attachments.existingMedia.length > 0) {
        const updatedMedia = this.attachments.existingMedia.filter(att => att.media != null && att.media.updated);
        if (updatedMedia.length > 0) {
          this.updateAttachments({
            media: Vue.filter('prepareMedia')(updatedMedia),
          });
        }
      }
      if (this.attachments.media != null || this.attachments.files != null || this.attachments.links != null) {
        this.attachments.institutionCode = this.shownSubscription.institutionCode;
        this.attachments.ownerInstitutionProfileId = this.institutions.find(
          inst => inst.institutionCode === this.shownSubscription.institutionCode
        ).institutionProfileId;

        try {
          const payload = {
            commonInboxId:
              this.shownSubscription.mailBoxOwner.mailBoxOwnerType == messageOwnerTypes.COMMON_INBOX
                ? this.shownSubscription.mailBoxOwner.id
                : null,
            otpInboxId:
              this.shownSubscription.mailBoxOwner.mailBoxOwnerType == messageOwnerTypes.OTP_INBOX
                ? this.shownSubscription.mailBoxOwner.id
                : null,
            text: this.message,
            subscriptionId: this.shownSubscription.id,
            attachmentIds: [],
          };
          if (this.attachments.attachedSecureDocumentIds && this.attachments.attachedSecureDocumentIds.length > 0) {
            const createLinksPayload = {
              documentIds: this.attachments.attachedSecureDocumentIds,
              ownerInstitutionProfileId: this.attachments.ownerInstitutionProfileId,
            };
            const createLinksRsp = await this.createDocumentLinks(createLinksPayload);
            payload.attachmentIds = payload.attachmentIds.concat(createLinksRsp.map(item => item.id));
          }
          this.attachments.attachedSecureDocumentIds = [];
          const createAttachmentsRsp = await this.createAttachments(this.attachments);
          const attachments = createAttachmentsRsp.files
            .concat(createAttachmentsRsp.links)
            .concat(createAttachmentsRsp.media)
            .concat(createAttachmentsRsp.documents)
            .concat(this.attachments.existingMedia);
          payload.attachmentIds = payload.attachmentIds.concat(attachments.map(att => att.id));
          this.onSaveMessage(payload);
        } catch (err) {
          this.isLoading = false;
        }
      } else {
        const payload = {
          commonInboxId:
            this.shownSubscription.mailBoxOwner.mailBoxOwnerType == messageOwnerTypes.COMMON_INBOX
              ? this.shownSubscription.mailBoxOwner.id
              : null,
          otpInboxId:
            this.shownSubscription.mailBoxOwner.mailBoxOwnerType == messageOwnerTypes.OTP_INBOX
              ? this.shownSubscription.mailBoxOwner.id
              : null,
          text: this.message,
          subscriptionId: this.shownSubscription.id,
          attachmentIds: this.attachments.existingMedia.map(att => att.id),
        };
        this.onSaveMessage(payload);
      }
    },
    onSaveMessage(payload) {
      if (!payload.subscriptionId) {
        return;
      }
      let savingMessageAction = this.replyMessage;
      if (this.isEditingMessage) {
        savingMessageAction = this.editMessage;
        payload.messageId = this.editedMessageDto.id;
      }
      this.setCleanEditor(true);
      savingMessageAction(payload)
        .then(() => {
          this.$emit('replySent', this.hasSecureDocuments);
          this.resetMessageTextarea();
          this.restartPollingService();
        })
        .catch(() => null)
        .finally(() => {
          this.isLoading = false;
        });
    },

    postMessage() {
      this.prepareMessage();
    },
    checkForAutoreply() {
      this.validateMessageText();
      if (!this.validMessageText || !this.validMessageTextLength) {
        return;
      }
      if (this.autoReply && this.autoReply.id !== undefined && this.autoReply.id !== null) {
        let today = new Date();
        today = Vue.filter('unixDate')(today);
        if (
          this.autoReply.startDateTime <= today &&
          (today <= this.autoReply.endDateTime || this.autoReply.endDateTime === null)
        ) {
          this.$refs.autoReplyModal.show();
        } else {
          this.postMessage();
        }
      } else {
        this.postMessage();
      }
    },
    cancelWarning() {
      this.$refs.autoReplyModal.hide();
    },
    acceptWarning() {
      this.$refs.autoReplyModal.hide();
      this.postMessage();
    },
    onCancelClicked() {
      if (this.$store.state.messages.currentDraftMessage.length > 0) {
        this.$refs.draftDiscardWarningModal.show();
      } else {
        this.onCancelEditingMessage();
      }
    },
    collapseReplyBox() {
      // Moved to nextTick to wait for any validations in watcher to run first
      this.$nextTick(() => {
        this.setShowTextarea(false);
        this.validMessageText = true;
        this.validMessageTextLength = true;
      });
    },
    enableTextarea() {
      if (!this.disabled) {
        this.setCleanEditor(false);
        this.setShowTextarea(true);
        // resize conversation to fixed with text area, scroll to bottom
        this.$nextTick(() => {
          $('.threads-container').animate(
            {
              scrollTop: $('.threads-container').prop('scrollHeight'),
            },
            1000
          );
        });
      }
    },
    calculatePlaceHolder() {
      let threadParticipants = this.shownSubscription.recipients.filter(
        r => r.leaveTime == null && r.mailBoxOwner.profileId !== parseInt(this.profile.profileId)
      );
      let commonInbox = null;
      if (this.shownSubscription.mailBoxOwner.mailBoxOwnerType == messageOwnerTypes.COMMON_INBOX) {
        threadParticipants = this.shownSubscription.recipients.filter(
          r => r.leaveTime == null && r.mailBoxOwner.id != this.chosenFolderAndMailOwner.mailOwnerId
        );
        commonInbox = this.shownSubscription.recipients.find(
          r => r.mailBoxOwner.id == this.chosenFolderAndMailOwner.mailOwnerId
        );
      }
      if (threadParticipants.length > 0) {
        if (commonInbox != null) {
          this.recipientShortList =
            this.$options.filters.fromTextKey('MESSAGE_RESPOND_AS_COMMON_INBOX_PLACEHOLDER') +
            ' ' +
            commonInbox.fullName +
            ' ' +
            this.$options.filters.fromTextKey('MESSAGE_RESPOND_AS_COMMON_INBOX_PLACEHOLDER_TO') +
            ' ' +
            threadParticipants[0].answerDirectlyName;
        } else {
          if (threadParticipants[0].mailBoxOwner.mailBoxOwnerType !== messageOwnerTypes.OTP_INBOX) {
            this.recipientShortList = threadParticipants[0].answerDirectlyName;
          } else {
            this.recipientShortList = threadParticipants[0].fullName;
          }
        }
        if (threadParticipants.length >= 2) {
          for (let i = 0; i < 2; i++) {
            if (threadParticipants[i + 1] != null) {
              if (threadParticipants[i + 1].mailBoxOwner.mailBoxOwnerType !== messageOwnerTypes.OTP_INBOX) {
                this.recipientShortList += ', ' + threadParticipants[i + 1].answerDirectlyName;
              } else {
                this.recipientShortList += ', ' + threadParticipants[i + 1].fullName;
              }
            }
          }
        }
        if (threadParticipants.length > 3) {
          this.recipientShortList +=
            ' + ' + (threadParticipants.length - 3) + ' ' + this.$options.filters.fromTextKey('MESSAGE_OTHERS');
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/elements/infobox.scss';
@import '../../../shared/assets/scss/core/animations.scss';

.btn-icon-submit {
  --font-size: 1.25rem;

  margin-right: 4px;
}

.sensitive-msg-container {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;

  .custom-checkbox {
    margin-bottom: 0;
  }
}

.placeholder-text {
  margin-top: 5px;
  margin-bottom: 15px;
}

.message-area {
  min-height: 72px;
  justify-content: center;

  .leave-timestamp {
    font-size: 0.7rem;
    display: block;
  }
}

.button-send {
  max-width: 130px;
  height: 42px;
  min-width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  white-space: nowrap;
  padding: 0 16px;
  &:hover {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.send-option-dropdown {
  max-width: 130px;
  height: 42px;
  width: 42px;

  /deep/.dropdown-toggle {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /deep/.send-options {
    margin-bottom: -42px;
    min-width: 250px;

    @include breakpoint-lg-down() {
      margin-right: 26px;
    }

    .b-dropdown-text {
      padding: 0.25rem 0.5rem;
      color: var(--color-primary-dark);
    }
    .close-icon {
      color: var(--color-primary-dark);
    }
    .dropdown-item {
      padding: 1rem 3rem 1rem 1rem;
    }
  }
}
.send-option {
  display: grid;
  grid-template-columns: 35px 1fr;
  .answer-option-label {
    text-transform: uppercase;
    font-weight: bold;
  }
  .answer-option-receiver {
    color: var(--color-grey);
    @include breakpoint-lg-down() {
      white-space: normal;
    }
  }
}
.send-button-icon {
  --font-size: 0.5rem;
}
.answer-icon {
  --font-size: 1.5rem;
}
.answer-all-icon {
  --font-size: 1.75rem;
}

.message-textarea {
  &.active {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  h5 {
    font-size: 14px;
    font-weight: normal;
  }
  .btn-link {
    padding: 14px 44px;
  }
}

.message-textarea {
  position: unset;
  .message-textarea-editor {
    border-color: transparent;
    background-color: transparent !important;
    border-radius: 5px;
    outline: 0;
    padding: 0;
    min-height: 48px;
    margin-bottom: 10px;
    .theme-employee & {
      color: $color-primary-darker-employee;
    }
    .theme-guardian & {
      color: $color-primary-darker-guardian;
    }
    .theme-child & {
      color: $color-primary-darker-child;
    }
    .theme-admin & {
      color: $color-primary-darker-admin;
    }
    /deep/ > .mce-container {
      height: 0;
      overflow: hidden;
    }
  }
  /deep/ .mce-top-part,
  /deep/ .mce-statusbar {
    display: none;
  }
  &.disabled {
    /deep/ .mce-edit-area {
      display: none;
    }
  }

  /deep/ .aula_editor .tox.tox-tinymce {
    @include breakpoint-lg-down() {
      min-height: auto;
    }
  }
}

.footer {
  margin-top: 0;
  margin-bottom: 8px;
}

.message-textarea.active {
  .message-textarea-editor {
    background-color: $color-input-focus;
    /deep/ > .mce-container {
      height: auto;
    }
  }
  /deep/ .mce-edit-area iframe {
    min-height: 125px !important;
  }
  /deep/ .mce-top-part,
  /deep/ .mce-statusbar {
    display: block;
  }
}

.prefixIcon {
  position: relative;
  width: 100%;
  background: $color-white;
  border-radius: 5px;
  margin: 15px 0;
  cursor: pointer;
  height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.preIcon {
  padding: 0 12px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
  height: 100%;
}

.message-area /deep/ footer {
  padding-bottom: 0;
}

.help-tooltip {
  /deep/.tooltip-inner.right {
    height: 210px;
    .tooltip-body {
      padding-bottom: 50px;
    }
  }
}

.message-utility-container {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  min-height: 25px;
}
</style>
