






























import { types } from '../../store/types/types.js';
import SubscriptionHeader from './SubscriptionHeader.vue';
import Conversation from './Conversation.vue';
import { mapActions, mapGetters } from 'vuex';
import { messageOwnerTypes } from '../../../shared/enums/messageOwnerTypes';
import { messageProviderKeyEnum } from '../../../shared/enums/messageProviderKeyEnum.js';
import { SubscriptionModel } from '../../../shared/models/subscription.model';
import { PropType } from 'vue';
import Button from '../../../shared/directives/button.js';

export default {
  components: {
    SubscriptionHeader: SubscriptionHeader,
    conversation: Conversation,
  },
  directives: { Button },
  provide() {
    return {
      [messageProviderKeyEnum.IS_THREAD_CREATOR]: () => this.isThreadCreator,
    };
  },
  props: {
    shownSubscription: { type: Object as PropType<SubscriptionModel>, default: null },
  },
  data: function () {
    return {
      showLoadMoreButton: Boolean,
      isCollapsed: false,
    };
  },
  computed: {
    ...mapGetters({
      moreMessagesExist: types.MESSAGES_GET_MORE_MESSAGES_EXITS,
      chosenFolderAndMailOwner: types.MESSAGES_GET_CHOSEN_FOLDER_AND_MAIL_OWNER,
      institutions: types.GET_INSTITUTIONS,
    }),
    isThreadCreator() {
      const threadCreatorMailBox = this.shownSubscription.threadCreator.mailBoxOwner;

      if (threadCreatorMailBox.mailBoxOwnerType !== this.chosenFolderAndMailOwner.mailOwnerType) {
        return false;
      }

      if (threadCreatorMailBox.mailBoxOwnerType === messageOwnerTypes.INSTITUTION_PROFILE) {
        const institutionProfileIds = this.institutions.map(inst => inst.institutionProfileId);
        return institutionProfileIds.includes(threadCreatorMailBox.id);
      }

      return threadCreatorMailBox.id === this.chosenFolderAndMailOwner.mailOwnerId;
    },
  },
  methods: {
    ...mapActions({
      loadMoreMessages: types.LOAD_MORE_MESSAGES,
    }),
    handleScroll(event) {
      const { scrollTop } = event.target;
      const subscriptionHeaderContainerHeight = this.$refs.threadScrollableContainer.querySelector(
        '.subscription-header-container'
      ).clientHeight;
      this.isCollapsed = scrollTop > subscriptionHeaderContainerHeight;
    },
    addMoreMessages() {
      this.loadMoreMessages({
        scrollElement: this.$el.closest('.threads-container'),
      });
    },
    toggleLoadMoreButton(shown) {
      this.showLoadMoreButton = shown;
    },
    scrollToHead() {
      this.$refs.threadScrollableContainer.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
};
